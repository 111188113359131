import React from 'react';
import styled from 'styled-components';

import {
  Caption,
  ContainerSmall,
  ContentFrame,
  ContentFrameSmall,
  H3,
  Regular,
  Small,
} from '@kilornd/modules-wrapper/es/components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Image, SectionWrapper } from 'components';
import { blue } from 'styles/colors';

const Error: React.FC = React.memo(() => {
  const { isTablet } = useQuery();

  return (
    <Wrapper>
      <ContainerSmall>
        <H3 margin={isTablet ? '0 0 1.5rem' : '0 0 2.5rem'} textAlign="center">
          Oops! Internal server error...
        </H3>
        <Image margin="0 auto 2rem" maxWidth="13.5rem" src="error_image" />
        <ContentFrameSmall>
          <Regular margin="0 0 1rem" textAlign="center">
            We are currently improving our program and are unable to finish your
            purchase. You will receive an email once new subscriptions will be
            available!
          </Regular>
          <Caption margin="0 0 1.5rem" textAlign="center">
            In case your card was charged, we will issue a full refund in the
            next 48 hours. For more information, please contact our support:
          </Caption>
          <Small margin="0 0 2.5rem" textAlign="center">
            <StyledLink href="mailto: hello@zerosmokes.com">
              hello@zerosmokes.com
            </StyledLink>
          </Small>
        </ContentFrameSmall>
      </ContainerSmall>
    </Wrapper>
  );
});

Error.displayName = 'Error';

export default Error;

const Wrapper = styled(SectionWrapper)`
  min-height: 70vh;
`;

const StyledLink = styled.a`
  color: ${blue};

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: inherit;
    opacity: 0.8;
  }
`;
